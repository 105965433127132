<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { onMounted,ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useInboundOrders from "../../../hooks/useInboundOrders";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import { shortDateWithTimeTz } from "../../../utils/dateUtils";
import InboundOrderLayout from "../../../layouts/InboundOrderLayout.vue";

import { defineEmits } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { useThemeStore } from "@/stores/themeStore";
import { getDefaultColDefs, getDefaultGridOptions } from "@/utils/aggridCommonConfigs";
import { connectToServerApi } from "@/utils/agGridUtils";
import { LicenseManager } from "ag-grid-enterprise";
const emit = defineEmits(["inboundOrder"]);

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const { inboundOrder, fetchInboundOrder } = useInboundOrders();

const userStore = useUserStore();
const route = useRoute();
const themeStore = useThemeStore();

let gridApi;
let context = ref(null);

const INBOUND_ORDERS_LP_TRANSACTIONS_URL = `/inbound_orders/${route.params.id}/lp_item_transactions?expand=product,created_by`;

const advancedFilterKeys = ["id", "created_at", "lp.number", "lp.lot", "qty", "created_by.name", "lp.expiry"];

onMounted(async () => {
  await fetchInboundOrder(route.params.id).then(() => {
    setPageTitle(`Inbound Order #IN-${inboundOrder.value.id}`);
  });
});

const onGridReady = (params) => {
  gridApi = params.api;
  context.value = {
    apiUrl: INBOUND_ORDERS_LP_TRANSACTIONS_URL,
    advancedFilterKeys: advancedFilterKeys,
  }
  gridApi.setGridOption("serverSideDatasource", connectToServerApi());
};

const defaultColDef = getDefaultColDefs();

const columnDefs = [
  { field: "id", minWidth: 50, headerName: "ID", colId: "id", cellDataType: "text"},
  {
    field: "created_at", minWidth: 220, headerName: "DATE", colId: "created_at", cellDataType: "text", 
    valueGetter: (params) => {
      return shortDateWithTimeTz(params.data.created_at);
    }
  },
  { field: "lp_item_id", minWidth: 50, headerName: "ITEM #", colId: "lp_item_id", cellDataType: "text", width: "300" },
  { field: "product.sku", minWidth: 200, headerName: "SKU", colId: "product.sku", cellDataType: "text", width: "300",
    cellRenderer: function (params) {
      if (params.data.product.sku) {
        return `<a href="/products/${params.data.product_id}">${params.data.product.sku}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: 'qty', minWidth: 120, headerName: "QUANTITY", colId: "qty", cellDataType: "text", width: "300",
    valueGetter: (params) => {
      return params.data.qty + " " + params.data.product.default_unit;
    }
  },
  { field: "lp.number", minWidth: 150, headerName: "LP NUMBER", colId: "lp.number", cellDataType: "text",
    cellRenderer: function (params) {
      if (params.data.lp.number) {
        return `<a href="/lps/${params.data.lp.id}">${params.data.lp.number}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "lp.bin.name", minWidth: 150, headerName: "LOCATION", colId: "lp.bin.name", width: "300",    
    cellRenderer: function (params) {
      if (params.data.lp.bin.name) {
        return `<a href="/bins/${params.data.lp.bin.id}">${params.data.lp.bin.name}</a>`;
      } else {
        return '';
      }
    },
  },
  { field: "lp.lot", minWidth: 150, headerName: "LOT CODE", colId: "lp.lot", cellDataType: "text", width: "300" },
  { field: "lp.expiry", minWidth: 150, headerName: "EXPIRY", colId: "lp.expiry", cellDataType: "text", width: "300" },
  { field: "created_by.name", minWidth: 150, headerName: "USER", colId: "created_by.name", cellDataType: "text", width: "300",
    cellRenderer: function (params) {
      if (params.data.created_by.name) {
        return `<a href="/users/${params.data.created_by.id}">${params.data.created_by.name}</a>`;
      } else {
        return '';
      }
    },
  },
];

const gridOptions = getDefaultGridOptions(columnDefs);

</script>

<template>
  <InboundOrderLayout :inboundOrder="inboundOrder" v-if="inboundOrder">
    <div v-if="userStore.user && inboundOrder" class="px-4">
      <div class="w-100 px-2">
        <div class="w-100">
          <OrderHeader
            orderType="inbound"
            :orderId="inboundOrder.id"
            :status="inboundOrder.order_status.short_label"
          />
        </div>

        <v-card class="mt-4 pa-2 w-auto" elevation="0">
          <v-card-title class="text-h5">Pallet Transactions</v-card-title>
        </v-card>

        <div class="inbound-lp-table">
          <AgGridVue 
            style="width: 100%; height: 100%"
            :class="themeStore.agTheme"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :context="context"
          ></AgGridVue>
        </div>
      </div>
    </div>
  </InboundOrderLayout>
</template>

<style scoped>
.inbound-lp-table {
  height: 570px;
  margin-top: 15px;
}
</style>